import * as artilleryLib from "./artillery-lib";
import * as artilleryOptions from "./artillery-options";

const simple = false;

export class GridCalculationRequest {
    mortarEasting: number;
    mortarNorthing: number;
    mortarHeight: number;
    enemyEasting: number;
    enemyNorthing: number;
    enemyHeight: number;
    artillery: keyof typeof artilleryOptions.artilleryMap;
    roundType: keyof typeof artilleryOptions.roundTypeMap;

    constructor(
        mortarEasting: number,
        mortarNorthing: number,
        mortarHeight: number,
        enemyEasting: number,
        enemyNorthing: number,
        enemyHeight: number,
        artillery: keyof typeof artilleryOptions.artilleryMap,
        roundType: keyof typeof artilleryOptions.roundTypeMap
    ) {
        this.mortarEasting = mortarEasting;
        this.mortarNorthing = mortarNorthing;
        this.mortarHeight = mortarHeight;
        this.enemyEasting = enemyEasting;
        this.enemyNorthing = enemyNorthing;
        this.enemyHeight = enemyHeight;
        this.artillery = artillery;
        this.roundType = roundType;
    }
}

export class PolarCalculationRequest {
    mortarEasting: number;
    mortarNorthing: number;
    mortarHeight: number;
    observerEasting: number;
    observerNorthing: number;
    observerHeight: number;
    observerToEnemyAzimuth: number;
    observerToEnemyHorizontal: number;
    observerToEnemyVertical: number;
    artillery: keyof typeof artilleryOptions.artilleryMap;
    roundType: keyof typeof artilleryOptions.roundTypeMap;

    constructor(
        mortarEasting: number,
        mortarNorthing: number,
        mortarHeight: number,
        observerEasting: number,
        observerNorthing: number,
        observerHeight: number,
        observerToEnemyAzimuth: number,
        observerToEnemyHorizontal: number,
        observerToEnemyVertical: number,
        artillery: keyof typeof artilleryOptions.artilleryMap,
        roundType: keyof typeof artilleryOptions.roundTypeMap
    ) {
        this.mortarEasting = mortarEasting;
        this.mortarNorthing = mortarNorthing;
        this.mortarHeight = mortarHeight;
        this.observerEasting = observerEasting;
        this.observerNorthing = observerNorthing;
        this.observerHeight = observerHeight;
        this.observerToEnemyAzimuth = observerToEnemyAzimuth;
        this.observerToEnemyHorizontal = observerToEnemyHorizontal;
        this.observerToEnemyVertical = observerToEnemyVertical;
        this.artillery = artillery;
        this.roundType = roundType;
    }
}

export function calculateElevationPolar(request: PolarCalculationRequest) {
    const artillery = artilleryOptions.artilleryMap[request.artillery];
    const roundType = artilleryOptions.roundTypeMap[request.roundType];
    if (!artillery) {
        return "Invalid artillery type.";
    }

    const mortarPos = new artilleryLib.GridCoord(
        request.mortarEasting,
        request.mortarNorthing
    );
    const observerPos = new artilleryLib.GridCoord(
        request.observerEasting,
        request.observerNorthing
    );
    const azimuth_result = artilleryLib.degAzimuthFromObserverRelative(
        mortarPos,
        observerPos,
        request.observerToEnemyAzimuth,
        request.observerToEnemyHorizontal
    );
    let mortarToEnemyAzimuth = azimuth_result[0];
    const mortarToEnemyDistance = azimuth_result[1];

    let elevationResult = artilleryLib.calculateElevation(
        artillery,
        roundType,
        request.observerHeight +
            request.observerToEnemyVertical -
            request.mortarHeight,
        mortarToEnemyDistance
    );
    console.log("elevationResults:", elevationResult);

    let results = [];
    for (let i = 0; i < elevationResult.length; i++) {
        const result = {
            charge: roundType.charges[elevationResult[i].chargeIndex].name,
            azimuth: Math.round(
                artillery.units.degToUnitMultiplier * mortarToEnemyAzimuth
            ),
            elevation: Math.round(elevationResult[i].elevation),
            timeToImpact: elevationResult[i].timeToImpact,
            maxOrd: elevationResult[i].maxOrd,
        };
        results.push(result);
    }
    console.log("results: ", results);
    for (let i = 0; i < results.length; i++) {
        if (results[i].elevation == -1) {
            results.splice(i, 1);
            i--;
        }
    }
    // TODO: I SHOULD PROBABLY MAKE THIS A FILTER ON THE FRONTEND SIDE, NOT IN BACKEND CALCULATIONS
    // BUT JUST TESTING IT HERE FOR NOW
    if (simple) {
        let shortestTTIIndex = 0;
        let shortestTTI = results[0].timeToImpact;
        for (let i = 1; i < results.length; i++) {
            if (results[i].timeToImpact < shortestTTI) {
                shortestTTI = results[i].timeToImpact;
                shortestTTIIndex = i;
            }
        }
        return [results[shortestTTIIndex]];
    }

    // TODO: implement error checking, probably need to change calculateElevation
    // to return with error if no charges can reach
    return results;
}

export function calculateElevationGrid(request: GridCalculationRequest) {
    const artillery = artilleryOptions.artilleryMap[request.artillery];
    const roundType = artilleryOptions.roundTypeMap[request.roundType];
    if (!artillery) {
        return "Invalid artillery type.";
    }

    const mortar_pos = new artilleryLib.GridCoord(
        request.mortarEasting,
        request.mortarNorthing
    );
    const enemy_pos = new artilleryLib.GridCoord(
        request.enemyEasting,
        request.enemyNorthing
    );
    let mortarToEnemyAzimuth = artilleryLib.degAzimuthFromGrids(
        mortar_pos,
        enemy_pos
    );
    const mortarToEnemyHorizDistance = artilleryLib.distancefunc(
        mortar_pos.easting,
        mortar_pos.northing,
        0,
        enemy_pos.easting,
        enemy_pos.northing,
        0
    );

    let elevationResult = artilleryLib.calculateElevation(
        artillery,
        roundType,
        request.enemyHeight,
        mortarToEnemyHorizDistance
    );

    let results = [];
    for (let i = 0; i < elevationResult.length; i++) {
        const result = {
            charge: roundType.charges[elevationResult[i].chargeIndex].name,
            azimuth: Math.round(
                artillery.units.degToUnitMultiplier * mortarToEnemyAzimuth
            ),
            elevation: Math.round(elevationResult[i].elevation),
            timeToImpact: elevationResult[i].timeToImpact,
            maxOrd: elevationResult[i].maxOrd,
        };
        results.push(result);
    }
    for (let i = 0; i < results.length; i++) {
        if (results[i].elevation == -1) {
            results.splice(i, 1);
            i--;
        }
    }
    // TODO: I SHOULD PROBABLY MAKE THIS A FILTER ON THE FRONTEND SIDE, NOT IN BACKEND CALCULATIONS
    // BUT JUST TESTING IT HERE FOR NOW
    if (simple) {
        let shortestTTIIndex = 0;
        let shortestTTI = results[0].timeToImpact;
        for (let i = 1; i < results.length; i++) {
            if (results[i].timeToImpact < shortestTTI) {
                shortestTTI = results[i].timeToImpact;
                shortestTTIIndex = i;
            }
        }
        return [results[shortestTTIIndex]];
    }

    // TODO: implement error checking, probably need to change calculateElevation
    // to return with error if no charges can reach
    return results;
}
