export class Charge {
    name: string;
    multiplier: number;

    constructor(name: string, multiplier: number) {
        this.name = name;
        this.multiplier = multiplier;
    }
}

export class RoundType {
    name: string;
    mass: number;
    airDrag: number;
    initialVelocity: number;
    charges: Array<Charge>;

    constructor(
        name: string,
        mass: number,
        airDrag: number,
        initialVelocity: number,
        charges: Array<Charge>
    ) {
        this.name = name;
        this.mass = mass;
        this.airDrag = airDrag;
        this.initialVelocity = initialVelocity;
        this.charges = charges;
    }
}

export class AngleUnits {
    name: string;
    radToUnitMultiplier: number;
    degToUnitMultiplier: number;

    constructor(
        name: string,
        radToUnitMultiplier: number,
        degToUnitMultiplier: number
    ) {
        this.name = name;
        this.radToUnitMultiplier = radToUnitMultiplier;
        this.degToUnitMultiplier = degToUnitMultiplier;
    }
}

export class Artillery {
    name: string;
    speedMultiplier: number;
    minAngle: number;
    maxAngle: number;
    roundTypes: Array<RoundType>;
    units: AngleUnits;

    constructor(
        name: string,
        speedMultiplier: number,
        minAngle: number,
        maxAngle: number,
        roundTypes: Array<RoundType>,
        units: AngleUnits
    ) {
        this.name = name;
        this.speedMultiplier = speedMultiplier;
        this.minAngle = minAngle;
        this.maxAngle = maxAngle;
        this.roundTypes = roundTypes;
        this.units = units;
    }
}

export class GridCoord {
    /**
     * Represents a coordinate point in a grid system with easting and northing values.
     * @param {number} easting - The easting coordinate value.
     * @param {number} northing - The northing coordinate value.
     */
    easting: number;
    northing: number;

    constructor(easting: number, northing: number) {
        this.easting = easting;
        this.northing = northing;
    }

    toString() {
        return `(${this.easting}, ${this.northing})`;
    }
}

export function distancefunc(
    x1: number,
    y1: number,
    z1: number,
    x2: number,
    y2: number,
    z2: number
) {
    return Math.sqrt(
        Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2) + Math.pow(z2 - z1, 2)
    );
}

export function rotatePointClockwise(
    point: Array<number>,
    degRotation: number
) {
    const radRotation = (degRotation * Math.PI) / 180;
    const cos = Math.cos(radRotation);
    const sin = Math.sin(radRotation);
    const rotationMatrix = [
        [cos, sin],
        [-sin, cos],
    ];
    return [
        point[0] * rotationMatrix[0][0] + point[1] * rotationMatrix[0][1],
        point[0] * rotationMatrix[1][0] + point[1] * rotationMatrix[1][1],
    ];
}

export function rotatePolygonClockwise(
    points: Array<Array<number>>,
    degRotation: number
) {
    const totalX = points.reduce((sum, point) => sum + point[0], 0);
    const totalY = points.reduce((sum, point) => sum + point[1], 0);
    const center = [totalX / points.length, totalY / points.length];

    const centeredPoints = points.map((point) => [
        point[0] - center[0],
        point[1] - center[1],
    ]);
    const rotatedPoints = centeredPoints.map((point) =>
        rotatePointClockwise(point, degRotation)
    );

    return rotatedPoints.map((point) => [
        point[0] + center[0],
        point[1] + center[1],
    ]);
}

export function generateLinearSheafPoints(
    startPoint: Array<number>,
    endPoint: Array<number>,
    mortarRadius: number
) {
    const distance = distancefunc(
        startPoint[0],
        startPoint[1],
        0,
        endPoint[0],
        endPoint[1],
        0
    );
    const numPoints = Math.ceil(distance / mortarRadius);
    const xStep = (endPoint[0] - startPoint[0]) / numPoints;
    const yStep = (endPoint[1] - startPoint[1]) / numPoints;
    return Array.from({ length: numPoints }, (_, i) => [
        startPoint[0] + i * xStep,
        startPoint[1] + i * yStep,
    ]);
}

export function ballisticSim(
    artillery: Artillery,
    roundType: RoundType,
    degElevation: number,
    targetHeight: number,
    charge: number
) {
    const deltaT = 0.001;
    const includeDrag = true;
    const f = roundType.airDrag;
    const m = roundType.mass;
    console.log(
        "f",
        f,
        "m",
        m,
        "roundtype.initialvelocity",
        roundType.initialVelocity,
        "artillery.speedmultiplier",
        artillery.speedMultiplier
    );
    const initialVelocity =
        roundType.initialVelocity *
        artillery.speedMultiplier *
        roundType.charges[charge].multiplier;
    let t = 0;
    let position = [0, 0];
    const gravity = [0, -9.81];
    let velocity = [
        initialVelocity * Math.cos((degElevation * Math.PI) / 180),
        initialVelocity * Math.sin((degElevation * Math.PI) / 180),
    ];
    let lastValidPosition = [...position];
    let maxHeightPos = [...position];

    while (velocity[1] > 0 || position[1] > targetHeight) {
        const speed = Math.sqrt(velocity[0] ** 2 + velocity[1] ** 2);
        const dragSpeed = (f * speed ** 2) / m;
        const dragDirection = [-velocity[0] / speed, -velocity[1] / speed];
        const dragVector = [
            dragSpeed * dragDirection[0],
            dragSpeed * dragDirection[1],
        ];
        const deceleration = includeDrag
            ? [dragVector[0] + gravity[0], dragVector[1] + gravity[1]]
            : gravity;

        velocity[0] += deceleration[0] * deltaT;
        velocity[1] += deceleration[1] * deltaT;

        lastValidPosition = [...position];
        position[0] += velocity[0] * deltaT;
        position[1] += velocity[1] * deltaT;

        if (position[1] > maxHeightPos[1]) {
            maxHeightPos = [...position];
        }

        t += deltaT;
    }

    return [lastValidPosition[0], t - deltaT, maxHeightPos[1]];
}

export function degAzimuthToVertAngle(degAzimuth: number) {
    return (90 - degAzimuth) % 360;
}

export function degAzimuthFromObserverRelative(
    mortarPos: GridCoord,
    observerPos: GridCoord,
    obsToEnemyDegAzimuth: number,
    obsToEnemyHorizDistance: number
) {
    const enemyPos = new GridCoord(
        Number(observerPos.easting) +
            Math.cos(
                (degAzimuthToVertAngle(obsToEnemyDegAzimuth) * Math.PI) / 180
            ) *
                obsToEnemyHorizDistance,
        Number(observerPos.northing) +
            Math.sin(
                (degAzimuthToVertAngle(obsToEnemyDegAzimuth) * Math.PI) / 180
            ) *
                obsToEnemyHorizDistance
    );
    console.log("enemyPos", enemyPos);

    const distance = distancefunc(
        mortarPos.easting,
        mortarPos.northing,
        0,
        enemyPos.easting,
        enemyPos.northing,
        0
    );
    console.log("distance", distance);

    return [Math.round(degAzimuthFromGrids(mortarPos, enemyPos)), distance];
}

export function degAzimuthFromGrids(startPos: GridCoord, endPos: GridCoord) {
    const startEndVector = new GridCoord(
        endPos.easting - startPos.easting,
        endPos.northing - startPos.northing
    );

    const temp = startEndVector.northing;
    startEndVector.northing = startEndVector.easting;
    startEndVector.easting = temp;

    let initial =
        (Math.atan2(startEndVector.northing, startEndVector.easting) * 180) /
        Math.PI;
    if (initial < 0) {
        initial = 360 + initial;
    }

    return initial;
}

export function calculateElevation(
    artillery: Artillery,
    roundType: RoundType,
    targetHeight: number,
    distance: number
) {
    let results = [];
    let angles = [];
    if (artillery.minAngle < 45) {
        angles.push("low");
    }
    if (artillery.maxAngle >= 45) {
        angles.push("high");
    }
    // TODO: add a check to make sure that the selected round type
    // is one of the roundtypes in the selected artillery?
    for (let i = 0; i < roundType.charges.length; i++) {
        // run twice to get low angle and high angle
        for (const angle of angles) {
            const maxAttempts = 100;
            let currentElevation = 45;
            let currentDistance = ballisticSim(
                artillery,
                roundType,
                currentElevation,
                targetHeight,
                i
            )[0];
            let maxElevation =
                angle == "low"
                    ? Math.min(45, artillery.maxAngle)
                    : Math.min(90, artillery.maxAngle);
            let minElevation =
                angle == "low"
                    ? Math.max(0, artillery.minAngle)
                    : Math.max(45, artillery.minAngle);
            let attemptCount = 0;

            while (
                Math.abs(currentDistance - distance) > 0.5 &&
                attemptCount < maxAttempts
            ) {
                attemptCount++;
                currentElevation = (minElevation + maxElevation) / 2;
                currentDistance = ballisticSim(
                    artillery,
                    roundType,
                    currentElevation,
                    targetHeight,
                    i
                )[0];
                console.log(
                    "currentElevation",
                    currentElevation,
                    "currentDistance",
                    currentDistance,
                    "charge",
                    i
                );
                if (angle == "low") {
                    if (currentDistance < distance) {
                        minElevation = currentElevation;
                    } else {
                        maxElevation = currentElevation;
                    }
                } else {
                    if (currentDistance < distance) {
                        maxElevation = currentElevation;
                    } else {
                        minElevation = currentElevation;
                    }
                }
            }
            if (Math.abs(currentDistance - distance) > 0.5) {
                results.push({
                    elevation: -1,
                    timeToImpact: -1,
                    maxOrd: -1,
                    chargeIndex: i,
                    angle: angle,
                });
                continue;
            }

            let elevation =
                radians(currentElevation) * artillery.units.radToUnitMultiplier;

            const ballisticSimResult = ballisticSim(
                artillery,
                roundType,
                currentElevation,
                targetHeight,
                i
            );

            results.push({
                elevation: Math.round(elevation),
                timeToImpact: parseFloat(ballisticSimResult[1].toFixed(1)),
                maxOrd: Math.round(ballisticSimResult[2]),
                chargeIndex: i,
                angle: angle,
            });
        }
    }
    return results;
}

export function generateDistanceElevationTable() {
    // TODO: Implement new version with charges
}

export function calculateAdjustedCoordinates(
    targetPos: GridCoord,
    correction: GridCoord,
    observerToEnemyDegAzimuth: number
) {
    let correctionDirection = 0;
    if (correction.easting > 0) {
        correctionDirection = (observerToEnemyDegAzimuth + 90) % 360;
    } else {
        correctionDirection = (observerToEnemyDegAzimuth - 90) % 360;
    }
    let correctionUnitVector = [
        Math.cos(radians(degAzimuthToVertAngle(correctionDirection))),
        Math.sin(radians(degAzimuthToVertAngle(correctionDirection))),
    ];
    let azimuthUnitVector = [
        Math.cos(radians(degAzimuthToVertAngle(observerToEnemyDegAzimuth))),
        Math.sin(radians(degAzimuthToVertAngle(observerToEnemyDegAzimuth))),
    ];
    let correctionVector = correctionUnitVector.map(
        (x) => x * correction.easting
    ); // left/right
    let azimuthVector = azimuthUnitVector.map((x) => x * correction.northing); // add/drop
    let totalAdjustmentVector = [
        correctionVector[0] + azimuthVector[0],
        correctionVector[1] + azimuthVector[1],
    ];
    let adjustedPos = new GridCoord(
        targetPos.easting + totalAdjustmentVector[0],
        targetPos.northing + totalAdjustmentVector[1]
    );
    return adjustedPos;
}

export function radians(degrees: number) {
    return degrees * (Math.PI / 180);
}

export function degrees(radians: number) {
    return radians * (180 / Math.PI);
}
