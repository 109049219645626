import * as artilleryLib from "./artillery-lib";

export const NATO_UNITS = new artilleryLib.AngleUnits(
    "Nato Mils",
    1018.6,
    artilleryLib.radians(1018.6)
);
export const RU_UNITS = new artilleryLib.AngleUnits(
    "Russian Mils",
    954.9,
    artilleryLib.radians(954.9)
);

export const artilleryOptions = [
    { label: "M252", value: "m252" },
    { label: "2B14", value: "tb14" },
];

export const roundTypeOptions = [
    { label: "M252 High-Explosive", value: "m252HE" },
    { label: "M252 Illumination", value: "m252IL" },
    { label: "M252 Practice", value: "m252PR" },
    { label: "M252 Smoke", value: "m252SM" },
    { label: "2B14 High-Explosive", value: "tb14HE" },
    { label: "2B14 Illumination", value: "tb14IL" },
    { label: "2B14 Smoke", value: "tb14SM" },
];

export const roundTypeMap = {
    m252HE: new artilleryLib.RoundType("m252HE", 4.06, 0.000462, 66, [
        new artilleryLib.Charge("0", 1),
        new artilleryLib.Charge("1", 1.531),
        new artilleryLib.Charge("2", 2.085),
        new artilleryLib.Charge("3", 2.541),
        new artilleryLib.Charge("4", 2.977),
    ]),
    m252IL: new artilleryLib.RoundType("m252IL", 4, 0.001488, 152, [
        new artilleryLib.Charge("1", 0.638),
        new artilleryLib.Charge("2", 1),
        new artilleryLib.Charge("3", 1.281),
        new artilleryLib.Charge("4", 1.596),
    ]),
    m252PR: new artilleryLib.RoundType("m252PR", 4.26, 0.000469, 66, [
        new artilleryLib.Charge("0", 1),
        new artilleryLib.Charge("1", 1.573),
        new artilleryLib.Charge("2", 2.082),
        new artilleryLib.Charge("3", 2.532),
        new artilleryLib.Charge("4", 2.932),
    ]),
    m252SM: new artilleryLib.RoundType("m252SM", 4.86, 0.0009139, 137, [
        new artilleryLib.Charge("1", 0.666),
        new artilleryLib.Charge("2", 0.959),
        new artilleryLib.Charge("3", 1.184),
        new artilleryLib.Charge("4", 1.387),
    ]),
    tb14HE: new artilleryLib.RoundType("tb14HE", 3.1, 0.000615, 76, [
        new artilleryLib.Charge("0", 1),
        new artilleryLib.Charge("1", 1.321),
        new artilleryLib.Charge("2", 1.736),
        new artilleryLib.Charge("3", 2.087),
        new artilleryLib.Charge("4", 2.455),
    ]),
    tb14IL: new artilleryLib.RoundType("tb14IL", 3.51, 0.001836, 127, [
        new artilleryLib.Charge("1", 0.698),
        new artilleryLib.Charge("2", 1.111),
        new artilleryLib.Charge("3", 1.512),
        new artilleryLib.Charge("4", 2.154),
    ]),
    tb14SM: new artilleryLib.RoundType("tb14SM", 3.48, 0.000655, 71, [
        new artilleryLib.Charge("0", 1),
        new artilleryLib.Charge("1", 1.339),
        new artilleryLib.Charge("2", 1.748),
        new artilleryLib.Charge("3", 2.086),
    ]),
};

export const m252_options = ["m252HE", "m252IL", "m252PR", "m252SM"];

export const round_options = {
    m252: ["m252HE", "m252IL", "m252PR", "m252SM"],
    tb14: ["tb14HE", "tb14IL", "tb14SM"],
};

const m252_rounds = [
    roundTypeMap["m252HE"],
    roundTypeMap["m252IL"],
    roundTypeMap["m252PR"],
    roundTypeMap["m252SM"],
];

const tb14_rounds = [
    roundTypeMap["tb14HE"],
    roundTypeMap["tb14IL"],
    roundTypeMap["tb14SM"],
];

export const artilleryMap = {
    m252: new artilleryLib.Artillery(
        "m252",
        1,
        45,
        90,
        m252_rounds,
        NATO_UNITS
    ),
    tb14: new artilleryLib.Artillery("tb14", 1, 45, 90, tb14_rounds, RU_UNITS),
};
