import React, { useState } from "react";
import { Button, InputNumber, InputPicker, Stack } from "rsuite";
import * as calculate from "../utils/calculate";
import * as artilleryOptions from "../utils/artillery-options";

function CalculateElevationPolarForm() {
    const [mortarEasting, setMortarEasting] = useState(0);
    const [mortarNorthing, setMortarNorthing] = useState(0);
    const [mortarHeight, setMortarHeight] = useState(0);
    const [observerEasting, setObserverEasting] = useState(0);
    const [observerNorthing, setObserverNorthing] = useState(0);
    const [observerHeight, setObserverHeight] = useState(0);
    const [observerToEnemyAzimuth, setObserverToEnemyAzimuth] = useState(0);
    const [observerToEnemyHorizontal, setObserverToEnemyHorizontal] =
        useState(0);
    const [observerToEnemyVertical, setObserverToEnemyVertical] = useState(0);
    const [artillery, setArtillery] =
        useState<keyof typeof artilleryOptions.artilleryMap>("m252");
    const [roundType, setRoundType] =
        useState<keyof typeof artilleryOptions.roundTypeMap>("m252HE");

    const [result, setResult] = useState<any>(null);
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState<any>(false);

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const data = {
            mortarEasting: mortarEasting,
            mortarNorthing: mortarNorthing,
            mortarHeight: mortarHeight,
            observerEasting: observerEasting,
            observerNorthing: observerNorthing,
            observerHeight: observerHeight,
            observerToEnemyAzimuth: observerToEnemyAzimuth,
            observerToEnemyHorizontal: observerToEnemyHorizontal,
            observerToEnemyVertical: observerToEnemyVertical,
            artillery: artillery,
            roundType: roundType,
        };

        setLoading(true);
        try {
            const response = calculate.calculateElevationPolar(data);
            console.log(response);
            setResult(response);
            setError(null);
        } catch (err) {
            console.log(err);
            setError("Error calculating elevation.");
            setResult(null);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Stack spacing={10} direction="column">
                    <Stack alignItems="flex-start" direction="column">
                        <label>Artillery</label>
                        <InputPicker
                            size="sm"
                            value={artillery}
                            data={artilleryOptions.artilleryOptions}
                            onChange={(value) => setArtillery(value)}
                        />
                    </Stack>
                    <Stack alignItems="flex-start" direction="column">
                        <label>Round Type</label>
                        <InputPicker
                            size="sm"
                            value={roundType}
                            data={artilleryOptions.roundTypeOptions.filter(
                                (item: any) =>
                                    artilleryOptions.round_options[
                                        artillery
                                    ].includes(item.value)
                            )}
                            onChange={(value) => setRoundType(value)}
                        />
                    </Stack>
                    <Stack alignItems="flex-start" direction="column">
                        <label>Mortar Easting</label>
                        <InputNumber
                            size="sm"
                            onChange={(value) =>
                                setMortarEasting(value as number)
                            }
                            required
                        />
                    </Stack>
                    <Stack alignItems="flex-start" direction="column">
                        <label>Mortar Northing</label>
                        <InputNumber
                            size="sm"
                            onChange={(value) =>
                                setMortarNorthing(value as number)
                            }
                            required
                        />
                    </Stack>
                    <Stack alignItems="flex-start" direction="column">
                        <label>Mortar Height</label>
                        <InputNumber
                            size="sm"
                            onChange={(value) =>
                                setMortarHeight(value as number)
                            }
                            required
                        />
                    </Stack>
                    <Stack alignItems="flex-start" direction="column">
                        <label>Observer Easting</label>
                        <InputNumber
                            size="sm"
                            onChange={(value) =>
                                setObserverEasting(value as number)
                            }
                            required
                        />
                    </Stack>
                    <Stack alignItems="flex-start" direction="column">
                        <label>Observer Northing</label>
                        <InputNumber
                            size="sm"
                            onChange={(value) =>
                                setObserverNorthing(value as number)
                            }
                            required
                        />
                    </Stack>
                    <Stack alignItems="flex-start" direction="column">
                        <label>Observer Height</label>
                        <InputNumber
                            size="sm"
                            onChange={(value) =>
                                setObserverHeight(value as number)
                            }
                            required
                        />
                    </Stack>
                    <Stack alignItems="flex-start" direction="column">
                        <label>Observer to Enemy Azimuth</label>
                        <InputNumber
                            size="sm"
                            onChange={(value) =>
                                setObserverToEnemyAzimuth(value as number)
                            }
                            required
                        />
                    </Stack>
                    <Stack alignItems="flex-start" direction="column">
                        <label>Observer to Enemy Horizontal</label>
                        <InputNumber
                            size="sm"
                            onChange={(value) =>
                                setObserverToEnemyHorizontal(value as number)
                            }
                            required
                        />
                    </Stack>
                    <Stack alignItems="flex-start" direction="column">
                        <label>Observer to Enemy Vertical</label>
                        <InputNumber
                            size="sm"
                            onChange={(value) =>
                                setObserverToEnemyVertical(value as number)
                            }
                            required
                        />
                    </Stack>
                    <Button
                        type="submit"
                        appearance="primary"
                        disabled={loading}
                    >
                        {loading ? "Calculating..." : "Calculate"}
                    </Button>
                </Stack>
            </form>
            {result && (
                <div className="mt-4">
                    <h2>Result</h2>
                    {result.map((result: any) => (
                        <>
                            <p>
                                <strong>Charge:</strong> {result.charge}
                            </p>
                            <p>
                                <strong>Azimuth:</strong> {result.azimuth}{" "}
                                {
                                    artilleryOptions.artilleryMap[artillery]
                                        .units.name
                                }
                            </p>
                            <p>
                                <strong>Elevation:</strong> {result.elevation}{" "}
                                {
                                    artilleryOptions.artilleryMap[artillery]
                                        .units.name
                                }
                            </p>
                            <p>
                                <strong>Time to Impact:</strong>{" "}
                                {result.timeToImpact} seconds
                            </p>
                            <p>
                                <strong>Max Ord:</strong> {result.maxOrd} m
                            </p>
                        </>
                    ))}
                </div>
            )}
            {error && (
                <div className="alert alert-danger mt-4" role="alert">
                    {error}
                </div>
            )}
        </>
    );
}

export default CalculateElevationPolarForm;
