import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import { Analytics } from "@vercel/analytics/react";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <>
            <App />
            <Analytics />
        </>
    </React.StrictMode>
);
